import React, { FC } from 'react';
import LoginForm from '../../organisms/LoginForm/LoginForm';

interface LoginPageProps {
    setReferral:React.Dispatch<React.SetStateAction<any>>,
}

const LoginPage: FC<LoginPageProps> = ({setReferral}) => {
    return (
        <><LoginForm /></>
    )
}

export default LoginPage;