import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../app/helper/axiosInstance";
import { ApiConstants } from "../../app/utils/apis/ApiConstants";
import axios from "axios";
import { apiClient } from "../../app/helper/axiosHelper";
import { log } from "console";

// Define types for your state and API responses
interface AuthState {
    otpId: string | null;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    data: any;
    access_token: string | null;
    refresh_token: string | null;
    user: any;
    isAuthenticated: boolean;
    referal: number|null
}

interface SendOtpPayload {
    countryCode: string;
    phoneNumber: string;
    portal: string
}

interface RegisterPayload {
    countryCode: string;
    phoneNumber: string;
    role: number;
    name: string;
    referral_with: any;
    send_otp_for_google: boolean
}

interface VerifyOtpPayload {
    countryCode: string;
    phoneNumber: string;
    otpId: string;
    otp: string;
}

interface ResendOtpPayload {
    otpId: string;
}

// Define the initial state
const initialState: AuthState = {
    otpId: null,
    status: 'idle',
    error: null,
    data: null,
    access_token: null,
    refresh_token: null,
    user: null,
    isAuthenticated: false,
    referal:null
};

// Async thunk for sending OTP
export const sendOtp = createAsyncThunk(
    'auth/sendOtp',
    async ({ phoneNumber, countryCode, portal }: SendOtpPayload, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${BASE_URL}${ApiConstants.SENDOTP}`, {
                country_code: countryCode,
                phone_number: phoneNumber,
                portal: portal
            });
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

// Async thunk for verifying OTP
export const verifyOtp = createAsyncThunk(
    'auth/verifyOtp',
    async ({ countryCode, phoneNumber, otpId, otp }: VerifyOtpPayload, { rejectWithValue }) => {
        try {
            const response = await apiClient.post(`${BASE_URL}${ApiConstants.LOGIN}`, {
                country_code: countryCode,
                phone_number: phoneNumber,
                otp_id: otpId,
                otp,
            }); 
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response ? error.response.data : error.message);
        }
    }
);

// Async thunk for resending OTP
export const resendOtp = createAsyncThunk(
    'auth/resendOtp',
    async ({ otpId }: ResendOtpPayload) => {
        const response = await apiClient.post(`${BASE_URL}${ApiConstants.RESENDOTP}`, {
            otp_id: otpId,
        });
        return response.data;
    }
);

// Async thunk for refreshing the access token
export const refreshAccessToken = createAsyncThunk(
    'auth/refreshAccessToken',
    async (_, { rejectWithValue }) => {
        try {
            const refresh_token = localStorage.getItem('refresh_token');
            console.log('refresh_token :>> ', refresh_token);
            if (!refresh_token) {
                throw new Error("No refresh token available");
            }
            const response = await apiClient.post(ApiConstants.REFRESHTOKEN, { refresh_token });
            console.log('response.data :>> ', response.data);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data || 'Failed to refresh token');
        }
    }
);

// registerOTP 
export const registerOTP = createAsyncThunk('auth/registerOTP', async ({ countryCode, phoneNumber, name, referral_with, role, send_otp_for_google }: RegisterPayload, { rejectWithValue }) => {
    try {
        const response = await apiClient.post(`${BASE_URL}${ApiConstants.REGISTER}`, {
            country_code: countryCode,
            phone_number: phoneNumber,
            name: name,
            referral_with: referral_with,
            role: role,
            send_otp_for_google: send_otp_for_google
        });
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response ? error.response.data : error.message);
    }
});

// Async thunk for logging out
export const logout = createAsyncThunk(
    'auth/logout',
    async (_, { rejectWithValue }) => {
        try {
            const accessToken = localStorage.getItem('access_token');
            // const response = await apiClient.get(`${BASE_URL}${ApiConstants.LOGOUT}`);
            const response = await apiClient.get(`${BASE_URL}${ApiConstants.LOGOUT}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response ? error.response.data : error.message);
        }
    }
);

// Create the auth slice
const authSlices = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        clearToken(state) {
            state.access_token = null;
            state.refresh_token = null;
            state.isAuthenticated = false;
            state.user = null;
            localStorage.removeItem("access_token");
            localStorage.removeItem("refresh_token");
        },
        setToken(state, action: PayloadAction<{ data: { token_details: { access_token: string, refresh_token: string } } }>) {
            state.access_token = action.payload.data.token_details.access_token;
            state.refresh_token = action.payload.data.token_details.refresh_token;
            state.isAuthenticated = true;
            localStorage.setItem("access_token", state.access_token);
            localStorage.setItem("refresh_token", state.refresh_token);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(sendOtp.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(sendOtp.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.otpId = action.payload.data.otp_id;
                state.error = null;
            })
            .addCase(sendOtp.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            .addCase(verifyOtp.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(verifyOtp.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.error = null;
                if (action.payload.success) {
                    state.data = action.payload.data.user_details;
                    state.access_token = action.payload.data.token_details.access_token;
                    state.refresh_token = action.payload.data.token_details.refresh_token;
                    state.referal=action.payload.data.user_details.referral_code
                    console.log(action.payload.data, 'verigy');
                    
                    if (state.access_token) {
                        localStorage.setItem('access_token', state.access_token);
                    }

                    if (state.refresh_token) {
                        localStorage.setItem('refresh_token', state.refresh_token);
                    }
                }
            })
            .addCase(verifyOtp.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            .addCase(resendOtp.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(resendOtp.fulfilled, (state) => {
                state.status = 'succeeded';
                state.error = null;
            })
            .addCase(resendOtp.rejected, (state, action) => {
                state.status = 'failed';
            })
            .addCase(refreshAccessToken.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(refreshAccessToken.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.access_token = action.payload.data.access_token;
                if (state.access_token) {
                    localStorage.setItem("access_token", state.access_token);
                }
            })
            .addCase(refreshAccessToken.rejected, (state, action) => {
                state.status = 'failed';
            })
            .addCase(registerOTP.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(registerOTP.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.error = null;
                if (action.payload.success) {
                    state.data = action.payload.data;
                }
            })
            .addCase(registerOTP.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string || action.error.message as string;
            })
    }
});

export const { clearToken, setToken } = authSlices.actions
export const selectAuthStatus = (state: any) => state.auth?.status;
export const referralCode = (state: any) => state.auth?.referal;
export default authSlices.reducer;