import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clearToken, logout, referralCode } from '../../../redux/Slices/authSlice';
import { AppDispatch } from '../../../redux/store';
import Referral from '../../../assets/images/refrerral.png'
import Copy from '../../../assets/images/copy-img.png'
import INVITE from '../../../assets/images/invite.png'
import LOGO from '../../../assets/images/logo.png'
import { message } from 'antd';

const Homepage = () => {
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch()
    const [loading, setLoading] = useState<boolean>(false);
    const referral = useSelector(referralCode)
    console.log(referral, 'referralllllll');
    const onPressLogOut = async () => {
        // Clear local storage and reset the token in the Redux store
        localStorage.clear();
        dispatch(clearToken());
        setLoading(true)
        try {
            await dispatch(logout()).unwrap();
            setLoading(false)
        } catch (error) {
            console.error('Logout failed:', error);
        }

        // Redirect to the login page
        navigate("/login");
    };

    const copyToClipboard = () => {
        if (referral) {
            navigator.clipboard.writeText(referral)
                .then(() => {
                    message.success('Referral code copied!');
                })
                .catch(err => {
                    console.error('Failed to copy referral code:', err);
                });
        } else {
            alert('No referral code available to copy.');
        }
    };
    return (
        <div>
            {/* <div onClick={onPressLogOut} className='logout'>Logout</div> */}
                <div className='homepage'>
                    <img src={LOGO} className='logo-image-short'/>
                    <div> Welcome to the ArbVenture </div>
                    </div>
            <div style={{marginTop:"5%", display: "flex", flexDirection: "column", alignItems: "center" ,justifyContent:"center",marginBottom:"10%"}}>
               <div style={{display:"flex",alignItems:"center", justifyContent:"center", flexDirection:"column"}}>
<img src={INVITE} className='static-invite'/>
             
                <div className='invite-text'>Invite Friend and <br />grow together</div>
                <div style={{width:"100%"}}>
                <div className='code-text'>Referral code: </div>
              
                <div className='referral-main-container' >
                <div className='referral-container'> <img src={Referral}/>
                <div className='referral-text'> {referral || 'No Code Available'}</div>
                </div>
                <div className='copy-container' onClick={copyToClipboard}><img src={Copy} className='copy-img'/></div></div>
                </div>
                <div className='share-text'>Share this referral code with your friends</div>
                <div className='homepage'>Stay tuned for further updates</div>
                </div>
           </div>
        </div>


    )
}

export default Homepage