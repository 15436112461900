import React, { ChangeEvent } from 'react';
import './Input.scss';

interface InputProps {
    label: string;
    placeholder: string;
    type: string;
    id: string;
    name: string;
    value: string | number;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    className?: string;
    classNameInput?: string;
    disabled?: boolean;
}

const Input: React.FC<InputProps> = (props) => {
    const { label, placeholder, type, id, name, value, onChange, className, classNameInput, disabled } = props;

    return (
        <div className={className}>
            <div className="search_bar">
                <div className="form-label">{label}</div>
                <input
                    className={`${classNameInput} search-input`}
                    placeholder={placeholder}
                    name={name}
                    type={type}
                    id={id}
                    disabled={disabled}
                    value={value}
                    onChange={onChange}
                    onWheel={(e) => e.currentTarget.blur()} // TypeScript needs to specify `currentTarget`
                />
            </div>
        </div>
    );
};

export default Input;