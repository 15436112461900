import React, { FC, useEffect, useRef, useState } from 'react'
import Button from '../../atoms/Button/Button'
import { AppDispatch } from '../../../redux/store';
import { useDispatch } from 'react-redux';
import { resendOtp } from '../../../redux/Slices/authSlice';
import './VerifyLoginOtp.scss'

interface VerifyLoginProps {
    handleOtpChange: Function,
    otp: string[],
    setOtp: React.Dispatch<React.SetStateAction<string[]>>,
    handleVerifyOtp: Function,
    setActiveButton: React.Dispatch<React.SetStateAction<boolean>>,
    loading: boolean,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    otpId: string,
    errorMessage: string,
}

const VerifyLoginOtp: FC<VerifyLoginProps> = (props) => {
    const { handleOtpChange, otp, setOtp, handleVerifyOtp, setActiveButton, otpId, errorMessage, loading, setLoading } = props;

    const dispatch: AppDispatch = useDispatch();
    const inputsRef = useRef<any>([]);
    const [countdown, setCountdown] = useState(40);
    console.log('loading :>> ', loading);
    useEffect(() => {
        let timer: any;
        if (countdown > 0) {
            timer = setTimeout(() => {
                setCountdown(countdown - 1);
            }, 1000);
        }
        return () => clearTimeout(timer);
    }, [countdown]);

    const handleKeyDown = (e: any, index: number) => {
        if (e.key === "Backspace") {
            if (otp[index] === "" && index > 0) {
                let finalIdex = index - 1;
                inputsRef.current[finalIdex].focus();
            }
            setActiveButton(false);
        }
    };



    const handleInputChange = (e: any, index: number) => {
        const value = e.target.value;
        if (/^[0-9]$/.test(value) || value === '') {
            handleOtpChange(e, index);
            if (value !== '' && index < 3) {
                inputsRef.current[index + 1].focus();
            }
        }
    };

    // Handle to Resend otp
    const handleResendOtp = async () => {
        setLoading(true);
        if (countdown === 0) {
            setLoading(true);
            await dispatch(resendOtp({ otpId })); // Dispatch the resend OTP action
            setLoading(false);
            setCountdown(40); // Reset the countdown after resend
            setOtp(["", "", "", ""]);
        }
    };

    return (
        <> {!loading &&
            <div className='verify-container'>
                <div className='otp-container'>
                    {otp.map((digit, index) => (
                        <input
                            key={index}
                            type="any"
                            maxLength={1}
                            value={digit}
                            onChange={(e) => handleInputChange(e, index)}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                            ref={(el) => (inputsRef.current[index] = el)}
                            className='otp-input'
                            placeholder='-'
                        />
                    ))}
                </div>
                <div>
                    {errorMessage && <div style={{ color: 'red', fontSize: '12px', marginLeft: '15px' }}>{errorMessage}</div>
                    }
                </div>

                <div className='resend-container' >
                    <label className='resend-text'>Resend Code:
                        <span className=' resend-timer'>
                            {countdown > 0 ? (` Retry in ${countdown} sec`) : <div className='resend-button' onClick={handleResendOtp}>Resend</div>}
                        </span>
                    </label>
                </div>

                <div className='verify-button'>
                    <Button buttonTitle='Verify Now' onClick={() => { handleVerifyOtp() }} className='button-container' />
                </div>
            </div>
        }
        </>
    )
}

export default VerifyLoginOtp