import React, { useState } from 'react'
import Input from '../../atoms/Input/Input';
import Button from '../../atoms/Button/Button';
import Loader from '../../atoms/Loader/Loader';
import VerifyLoginOtp from '../VerifyLoginOtp/VerifyLoginOtp';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { referralCode, registerOTP, selectAuthStatus, sendOtp, verifyOtp } from '../../../redux/Slices/authSlice';
import { AppDispatch } from '../../../redux/store';
import { image } from '../../../app/utils/common/image';
import './LoginForm.scss';
 

const LoginForm = () => {
    const [showOtpModal, setShowOtpModal] = useState<boolean>(false);
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [countryCode, setCountryCode] = useState<string>('+91');
    const [otp, setOtp] = useState<string[]>(["", "", "", ""]);
    const [activeButton, setActiveButton] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [register, setRegister] = useState<boolean>(false);
    const [code, setCode] = useState<any>('');
    const [userName, setUserName] = useState<string>("");
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const authState = useSelector((state: any) => state.auth);
    const authStatus = useSelector(selectAuthStatus);
    

    // Handle to send otp
    const handleSendOtp = () => {
        setLoading(true);
        setErrorMessage('')
        dispatch(sendOtp({ phoneNumber, countryCode, portal: "app" })).then((action: any) => {
            // Dispatch the send OTP action
            setLoading(false);
            if (action.type === 'auth/sendOtp/fulfilled') { 
                setShowOtpModal(true);
                setErrorMessage('');
            } else if (action.type === 'auth/sendOtp/rejected') {
                console.log('action.payload.error?.message :>> ', action.payload.error?.message);
                setErrorMessage(action.payload.error?.message);
            }
        });
    };

    // Handle to verify otp
    const handleVerifyOtp = () => {
        const otpString = otp.join('');
        setLoading(true);
        setErrorMessage('');
        dispatch(verifyOtp({ countryCode, phoneNumber, otpId: authState.otpId, otp: otpString })) // Dispatch the verify OTP action
            .then((action: any) => {
                setLoading(false);
                if (action.type === 'auth/verifyOtp/fulfilled') {
                    const { data } = action.payload;
                    localStorage.setItem('access_token', data.token_details.access_token);
                    localStorage.setItem('refresh_token', data.token_details.refresh_token);
                    navigate('/home');
                } else {
                    const Error = action.payload?.error?.message || 'Failed to verify OTP';
                    if (action.payload?.error.message === "The entered OTP is incorrect. Please try again.") {
                        setErrorMessage(Error);
                    } else if (action.payload?.error.message === "No user found with this phone number.") {
                        console.log('error :>> ', action.payload?.error.message);
                        setErrorMessage(`${Error}Please register first`);
                        setShowOtpModal(false);
                        setRegister(true)
                    }
                    setErrorMessage(action.payload?.error?.message || 'Failed to verify OTP');
                }
            })
            .catch((err: any) => {
                setLoading(false);
                setErrorMessage('Error verifying OTP: ' + err.message);
            });
    };

    const handlePhoneChange = (event: any) => {
        if (event.target.value.length <= 10) {
            setPhoneNumber(event.target.value);
            setActiveButton(event.target.value.length === 10);
            setErrorMessage('')
        }
    };

    const handleOtpChange = (e: any, index: number) => {
        const newOtp = [...otp];
        newOtp[index] = e.target.value;
        setOtp(newOtp);
        setErrorMessage('')
    };

    // const handleRegister = () => {
    //     navigate("/signup")
    // }

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setErrorMessage('')
        switch (name) {
            case 'name':
                setUserName(value);
                break;
            case 'code':
                setCode(value);
                break;
            default:
                break;
        }
    }

    const handleRegister = () => {
        if (!userName || !phoneNumber) {
            setErrorMessage('Please enter your name and phone number.');
            return;
        }

        setLoading(true);
        setErrorMessage(''); // Clear any previous error message
        // dispatch(clearUserData());
        dispatch(registerOTP({ name: userName, countryCode, phoneNumber, referral_with: code, role: 2, send_otp_for_google: false }))
            .then((action: any) => {
                console.log('action :>> ', action);
                setLoading(false);
                const { success, error } = action.payload;
                if (success) {
                    setErrorMessage('');
                    const { data } = action.payload;
                    localStorage.setItem('access_token', data?.token_details.access_token);
                    localStorage.setItem('refresh_token', data?.token_details.refresh_token);
                    setShowOtpModal(true);
                    navigate('/home')
                } else if (error) {
                    const Error = error.message || 'Failed to register';
                    setErrorMessage(Error);
                }
            })
            .catch((err: any) => {
                setLoading(false);
                setErrorMessage('Error during registration: ' + err.message);
            });
    };

    return (
        <div className='login-form'>
            <div className="login-page">
                <div className="login-page-container">
                    <div className='login-logo'>
                        <div className='text-[24px] mt-[-45px] ml-[35px]'>
                            {/* <img src={image.IMG_LOGO} alt='' className='w-[180px] h-[78px] rounded-[24px]' /></div> */}
                        </div>
                        {/* <img src={image.LogoHere} alt='' className='login-image' /> */}
                    </div>
                    <div className="login-page-wrapper">
                        <div className="d-flex justify-content-center w-full">
                            <div className="arb-logo-container">
                                <div className="aside-logo">
                                    <img src={image.LOGO} className='logo-image' />
                                    {/* <div className='logo-text'>ARB Ventures</div> */}
                                </div>
                                {/* <div className="admin-panel">
                                    <span>Dashboard</span>
                                </div> */}
                            </div>
                        </div>
                        <div className="login-container">
                            <div> {!showOtpModal ? (register ? "Register" : "Log In") : "Verification Code(OTP)"}</div>
                            <p className="login-text">{!showOtpModal ? 'Enter login credentials to continue.' : 'Please enter the OTP received via SMS on the mobile number you have provided'} </p>
                        </div>

                        {!loading ? (
                            !showOtpModal && (
                                <div className="email-container">
                                    {register && <div>  <Input
                                        label="Name *"
                                        placeholder="Enter your name"
                                        type="text"
                                        id="text"
                                        name="name"
                                        value={userName}
                                        onChange={handleInputChange}
                                    />
                                        {errorMessage === 'Please enter your name and phone number.' && <div style={{ color: 'red', fontSize: '12px', marginLeft: '15px', marginTop: '5px' }}>Please Enter your name</div>}
                                    </div>}
                                    <div>
                                        <Input
                                            label="Phone Number"
                                            placeholder="Enter your phone number"
                                            type="tel"
                                            id="tel"
                                            name="phonenumber"
                                            value={phoneNumber}
                                            onChange={handlePhoneChange}
                                            classNameInput="input-container"
                                            disabled={register ? true : false}
                                        />
                                        {errorMessage && <div style={{ color: 'red', fontSize: '12px', marginLeft: '15px', marginTop: '5px' }}>{register ? "" : errorMessage}</div>}
                                    </div>
                                    {register && <div>  <Input
                                        label="Referal code"
                                        placeholder="Enter your Referal code"
                                        type="any"
                                        id="code"
                                        name="code"
                                        value={code}
                                        onChange={handleInputChange}
                                    />
                                        {errorMessage === '"referral_with" length must be at least 6 characters long' && <div style={{ color: 'red', fontSize: '12px', marginLeft: '15px', marginTop: '5px' }}>Referral code must be at least 6 digits long.</div>}
                                    </div>}
                                    {/* <Button buttonTitle='Get OTP' onClick={activeButton ? handleSendOtp : undefined} className={activeButton ? 'button-container' : 'active-button'} /> */}
                                    {!register ? <Button onClick={activeButton ? handleSendOtp : undefined} className={activeButton ? 'button-container' : 'active-button'} buttonTitle="Send OTP" /> :
                                        <Button onClick={handleRegister} buttonTitle="Register" className={activeButton ? 'button-container' : 'active-button'} />}

                                    {/* <div className="register-now">
                                        <p> Don't have an account? <span onClick={handleRegister}>Register Now</span></p>
                                    </div> */}
                                </div>
                            )
                        )
                            : (<div className="flex items-center justify-center">
                                <Loader />
                            </div>
                            )}

                        {showOtpModal && <VerifyLoginOtp
                            handleOtpChange={handleOtpChange}
                            otp={otp}
                            handleVerifyOtp={handleVerifyOtp}
                            setActiveButton={setActiveButton}
                            otpId={authState.otpId}
                            setOtp={setOtp}
                            errorMessage={errorMessage}
                            loading={loading}
                            setLoading={setLoading}
                        />}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginForm