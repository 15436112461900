import React, { FC, useState } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import LoginPage from '../../components/pages/LoginPage/LoginPage'
import Homepage from '../../components/pages/HomePage/Homepage'
import SignUpPage from '../../components/organisms/SignUpPage/SignUpPage'

const AppRoutes: FC = () => {
    const [referal, setReferral] = useState<any>();

    return (
        <>
            <Routes>
                <Route path='/signup' element={<SignUpPage />} />
                <Route path='/login' element={<LoginPage setReferral={setReferral} />} />
                <Route path='/signup' element={<SignUpPage />} />
                <Route path='/home' element={<Homepage />}/>
                <Route path='/' element={<Navigate to="/login" />} />
            </Routes>
        </>
    )
}

export default AppRoutes